<template>
  <v-hover v-slot:default="{ hover }">
    <v-card :class="`elevation-${hover ? 5 : 1}`"
            class="Card_Apuracao clickable" fill-height @click="selectItem(item)">
      <v-card-title class="Card_Title pa-2 justify-center">
        <popover-lista :campos="camposPopover" :offset-x="false" :offset-y="true"
                       :objeto="item" class="ApuracaoCardContrato__popover-lista" :nudge-width="300">
          <template slot="ativadorPopover">
            <span class="title text-center">{{ item.origemContrato || item.focoContrato }}</span>
          </template>
        </popover-lista>
      </v-card-title>
      <v-card-text class="Card_Content py-0">
        <v-row justify="center" align="center">
          <p class="headline font-weight-light">
            {{ getPercent(item.valorPercentual) }}
          </p>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center" align="center">
          <v-col cols="12" :sm="quantosEspacos(item)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-row justify="center" align="center" v-on="on">
                  <v-col cols="12" sm="12" class="py-0" style="text-align: center;">
                    {{ getMoney(item.valorRecebimento) }} <br />
                    <v-icon>attach_money</v-icon>
                  </v-col>
                </v-row>
              </template>
              <span>{{ $t('label.total_de_recebimento_contrato') }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" :sm="quantosEspacos(item)" v-if="item.extensaoRecursiva">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-row justify="center" align="center" v-on="on">
                  <v-col cols="12" sm="12" class="py-0" style="text-align: center;">
                    <p class="ma-0">{{ item.extensaoRecursiva }}</p>
                    <v-icon>category</v-icon>
                  </v-col>
                </v-row>
              </template>
              <span>{{ $t('label.categoria_produto_contrato') }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" :sm="quantosEspacos(item)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-row justify="center" align="center" v-on="on">
                  <v-col cols="12" sm="12" class="py-0" style="text-align: center;">
                    <p class="ma-0" v-if="dataFimEInicioIgual(item.dataFim, item.dataInicio)">{{ converterMesAno(item.dataInicio)}}</p>
                    <p class="ma-0" v-else>{{ `${converterMesAno(item.dataInicio)} ${$tc('label.a', 2)} ${converterMesAno(item.dataFim)}`}}</p>
                    <v-icon>calendar_today</v-icon>
                  </v-col>
                </v-row>
              </template>
              <span>{{ $t('label.vigencia_contrato') }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" :sm="quantosEspacos(item)" v-if="habilitarFinalizarApuracao">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-row justify="end" align="center" v-on="on">
                  <v-col cols="12" sm="12" class="py-0" style="text-align: center;">
                    <v-btn v-on="on" class="ma-0" icon @click="(e) => abrirFinalizacaoLote(e, item)">
                      <v-icon>done_all</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <span>{{ $tc('label.finalizar_apuracao', 2) }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import {
  getMoney,
  getPercent,
} from '../../common/functions/helpers';
import PopoverLista from '../../shared-components/PopoverLista';
import { generateComputed } from '../../common/functions/roles-computed-generator';

export default {
  name: 'ApuracaoCardContrato',
  components: {
    PopoverLista,
  },
  props: {
    item: Object,
  },
  computed: {
    ...generateComputed('APU_CONTR', [
      'canEdit',
    ]),
    camposPopover() {
      const campos = [];

      campos.push({
        campo: 'descricao',
        label: this.$t('label.descricao_contrato'),
      });

      campos.push({
        campo: 'unidadeNegocio',
        semLabel: true,
      });

      return campos;
    },
    habilitarFinalizarApuracao() {
      return this.item.temAcaoAguardandoApuracao
        && this.canEdit;
    },
  },
  methods: {
    quantosEspacos(item) {
      let espacos = 0.0;

      if (this.habilitarFinalizarApuracao) {
        espacos += 1.5;
      }

      if (item.extensaoRecursiva) {
        espacos += 1.5;
      }

      return Math.floor(6 - espacos);
    },
    getMoney,
    getPercent,
    selectItem(item) {
      this.$router.push({
        name: 'listarApuracoesContratoNivel2Contrato',
        params: {
          idContrato: item.codContrato,
        },
        query: {
          origemContrato: item.origemContrato || item.focoContrato,
        },
      });
    },
    abrirFinalizacaoLote(e, item) {
      e.stopPropagation();
      this.$router.push({
        name: 'finalizarLoteApuracoesContrato',
        params: {
          idContrato: item.codContrato,
        },
        query: {
          origemContrato: item.origemContrato || item.focoContrato,
        },
      });
    },
    dataFimEInicioIgual(dataFim, dataInicio) {
      return this.converterMesAno(dataFim) === this.converterMesAno(dataInicio);
    },
    converterMesAno(val) {
      return this.moment(val).format('MMM/YY');
    },
  },
};
</script>

<style>
  .ApuracaoCardContrato__popover-lista {
    width: 100%;
  }
  .clickable {
    cursor: pointer;
  }
  .Card_Apuracao {
    min-height: 146px !important;
    height: 100%;
  }
  .Card_Apuracao .Card_Title, .Card_Apuracao .Card_Content {
    color: #757575;
  }
</style>
